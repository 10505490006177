*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.red{
  color: red;
}

.container{
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 200px
}



.search-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

input[type="text"] {
  flex: 1;
  height: 40px;
  padding: 10px 30px;
  border: none;
  border-radius: 25px 0px 0px 25px;
  font-size: 16px;
  background-color: #f1f1f1;
  transition: all 0.3s ease;
}

input[type="text"]:focus {
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
}

.submit {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 0px 25px 25px 0px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit:hover {
  background-color: #3e8e41; /* Dark green */
}





/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  /* margin: 100px auto 0; */
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;
  margin:2px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}